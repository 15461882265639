import { useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import { Divider, Drawer, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { CPopover } from 'src/components/common/CPopover';
import useToggler from 'src/hooks/useToggler';
import useMatchMedia from 'src/hooks/useMatchMedia';
import { logOut } from 'src/store/user/actions';
import { userSelector } from 'src/store/user/selectors';

import { authPopoverProps } from '..';

import { AccountButton } from './AccountButton';
import { Content } from './Content';
import { userNavMenu } from './constants';
import { ListItemHeader, StyledList } from './styles';


interface AccountProps {
  isContrast?: boolean;
}

export const Account = ({ isContrast = false }: AccountProps) => {
  const { toggler, handleClose, handleOpen } = useToggler();
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMatchMedia(theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const { info } = useSelector(userSelector);

  const anchorRef = useRef<HTMLDivElement>(null);
  
  const handleOpenBurgerMenu = () => {
    setIsBurgerMenuOpen(true);
  }

  const handleCloseBurgerMenu = () => {
    setIsBurgerMenuOpen(false);
  }
  const handleLogout = () => dispatch(logOut());

  const generateName = () => `${info?.name ? info?.name : ''} ${info?.surname ? info?.surname : ''}`

  return (
    <div ref={anchorRef}>
      <AccountButton onClick={isMobile ? handleOpenBurgerMenu : handleOpen} isContrast={isContrast} />
      <CPopover
        {...authPopoverProps}
        open={toggler}
        anchorEl={anchorRef.current}
        onClickOutside={handleClose}
      >
        <Content onClose={handleClose} />
      </CPopover>

      <Drawer open={isBurgerMenuOpen} onClose={handleCloseBurgerMenu} anchor="right">
        <StyledList>
          <ListItem disablePadding onClick={handleCloseBurgerMenu}>
            <ListItemHeader>
              {info?.name || info?.surname ? (
                <Typography variant="h3">
                  {generateName()}
                </Typography>
              ) : null}
              <ListItemText primary={info?.email} />
            </ListItemHeader>
          </ListItem>
          <Divider />
          {userNavMenu.map(({ label, url }) => (
            <ListItem key={label} disablePadding onClick={handleCloseBurgerMenu}>
              <Link to={url}>
                <ListItemButton>
                  <ListItemText primary={label} />
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
          <Divider />
          <ListItem disablePadding onClick={handleCloseBurgerMenu}>
            <Link to='/affiliate-dashboard'>
              <ListItemButton>
                <ListItemText primary="Affiliate dashboard" />
              </ListItemButton>
            </Link>
          </ListItem>
          <Divider />
          <ListItem disablePadding onClick={handleCloseBurgerMenu}>
            <ListItemButton onClick={handleLogout}>
              <ListItemText primary="Sign out" />
            </ListItemButton>
          </ListItem>
        </StyledList>
      </Drawer>
    </div>
  );
};
